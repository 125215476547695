import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import logoutAction from "actions/logoutAction";


class Logout extends React.Component {

  componentWillMount() {
      localStorage.clear();
      // localStorage.removeItem("appToken");
	    // localStorage.removeItem("firebaseUser");
      // this.setState({});
      // localStorage.removeItem("appToken");
      // localStorage.removeItem("firebaseUser");      
    }

    componentDidMount() {     
      this.props.logoutAction();
    }

  render() {
    localStorage.removeItem("appToken");
    localStorage.removeItem("firebaseUser");
      localStorage.clear();
      this.state = {};      
    return <Redirect to="/public/bio" />;
    
  }
}

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  logoutAction: () =>
    dispatch(logoutAction())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);

