import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

class UserProfile extends React.Component {
  firebaseUser = JSON.parse(localStorage.getItem("firebaseUser"))
  constructor(props) {
      super(props);
      this.state = { ...props }
      this.onChange = this.onChange.bind(this)      
  }  
  componentWillMount() {
    this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });    
  } 

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
    console.log('[print]', this.state)
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                </CardHeader>
                <CardBody>
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    <a href="#" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar"
                        src={this.state.authState.user.photoURL}
                      />
                      <h5 className="title">{this.firebaseUser.displayName}</h5>
                    </a>
                    <p className="description"></p>
                  </div>
                  <div className="card-description">
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="button-container">
                    <Button className="btn-icon btn-round" color="facebook">
                      <i className="fab fa-facebook" />
                    </Button>
                    <Button className="btn-icon btn-round" color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button className="btn-icon btn-round" color="google">
                      <i className="fab fa-google-plus" />
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default UserProfile;
