import firebase from "firebaseConfig";

const logoutAction = (email, password) => async dispatch => {
  firebase
    .auth()
    .signOut()
    .then(function() {
      dispatch({ type: "login", payload: "false" });
      localStorage.removeItem("appToken");
      localStorage.removeItem("firebaseUser");
    })
    .catch(function(error) {
      alert(error);
    });
};
export default logoutAction;
