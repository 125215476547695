// import Page from './Page';
import React, { Component } from 'react';
import { MdAddBox, MdDeleteForever, MdRefresh } from 'react-icons/md';
import {
  Card,
  Col,
  Row,
  Button,
  Table,
  Input
} from 'reactstrap';

// import { alertService } from '../services';

var defaultWidget = { select: false, id: 0, name: 'Position', maxVal: 'Max Pay/mo', minVal: 'Min Pay/mo', category: 'Job Category', subCategory: 'Job Sub-Category', preLaunch: 'true', monthsBeforeLaunch: '6' }

var widgetData = [
  { select: false, name: 'Historical Finance Data Rental', maxVal: 7000, minVal: 4000, category: 'Data', subCategory: 'Finance', preLaunch: true, monthsBeforeLaunch: 6 },
  { select: false, name: 'Historical Event Data Rental', maxVal: 2500, minVal: 1000, category: 'Data', subCategory: 'News', preLaunch: true, monthsBeforeLaunch: 6 },
  { select: false, name: 'Cloud Storage', maxVal: 1000, minVal: 500, category: 'Data', subCategory: 'Cloud', preLaunch: true, monthsBeforeLaunch: 6 },
  { select: false, name: 'Cloud Servers for Platform', maxVal: 5000, minVal: 1000, category: 'Server', subCategory: 'Cloud', preLaunch: true, monthsBeforeLaunch: 3 },
  { select: false, name: 'Rental Space for Hardware', maxVal: 2000, minVal: 1000, category: 'Rental', subCategory: 'Physical', preLaunch: true, monthsBeforeLaunch: 3 },
  // { select: false, name: 'NASDAQ/NYSE Data Redistribution', maxVal: 18000, minVal: 10000, category: 'Data', subCategory: 'Redistribution', preLaunch: false, monthsBeforeLaunch: 0 },
  // { select: false, name: 'CME/GLOBEX Data Redistribution', maxVal: 18000, minVal: 10000, category: 'Data', subCategory: 'Redistribution', preLaunch: false, monthsBeforeLaunch: 0 },

];

const widgetCols = [
  { header: '', name: 'select', style: { verticalAlign: 'top', width: '3em' } },
  { header: 'Id', name: 'id', style: { textAlign: 'center', verticalAlign: 'middle', width: '3em', color: '#6a82fb' } },
  { header: 'Name', name: 'name', style: { textAlign: 'center', verticalAlign: 'middle', width: '10em', color: '#6a82fb' } },
  { header: 'Min', name: 'minVal', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
  { header: 'Max', name: 'maxVal', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
  { header: 'Category', name: 'category', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
  { header: 'Sub-Category', name: 'subCategory', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
  // { header: 'Needed Pre-Launch', name: 'preLaunch', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
  { header: 'Months Before Launch', name: 'monthsBeforeLaunch', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
];



class MonthlyExpense extends Component {

  constructor(props) {
    super(props);

    for (var i = 0; i < widgetData.length; i++) {
      widgetData[i]['id'] = i
    }

    this.state = {
      ...props,
      widgets: widgetData,
      widgetColumns: widgetCols,
      minMonthly: 0,
      maxMonthly: 0,
      avgMonthly: 0,
    }

    var minMonthly = 0;
    var maxMonthly = 0;
    var avgMonthly = 0;
    for (i = 0; i < this.state.widgets.length; i++) {
      minMonthly += this.state.widgets[i].minVal;
      maxMonthly += this.state.widgets[i].maxVal;
      avgMonthly += (this.state.widgets[i].maxVal + this.state.widgets[i].minVal)/2.;
    }
    this.setState({minMonthly: minMonthly});
    this.setState({maxMonthly: maxMonthly});
    this.setState({avgMonthly: avgMonthly});
    this.props.onMonthlyExpenseUpdate(avgMonthly/1000.);
    // console.log("[monthly] Min: ", this.state.minMonthly, ", [monthly] Max: ", this.state.maxMonthly, ", [monthly] Avg: ", this.state.avgMonthly);
    console.log("Monthly Expense: ", this.props.monthlyExpense)

  }
  onInsertWidgetRow = () => {
    var lastWidgetId = 0;
    if (this.state.widgets.length > 0) {
      lastWidgetId = this.state.widgets[this.state.widgets.length - 1].id;
    }
    var newWidget = JSON.parse(JSON.stringify(defaultWidget));
    newWidget.id = lastWidgetId + 1;
    let data = this.state.widgets;
    data.push(newWidget);
    // can add REST call here to insert new record 
    this.setState({ widgets: data })
  }
  

  onUpdateWidgetRow = () => {
    var minMonthly = 0;
    var maxMonthly = 0;
    var avgMonthly = 0;
    for (var i = 0; i < this.state.widgets.length; i++) {
      minMonthly += this.state.widgets[i].minVal;
      maxMonthly += this.state.widgets[i].maxVal;
      avgMonthly += (this.state.widgets[i].maxVal + this.state.widgets[i].minVal)/2.;
    }
    this.setState({minMonthly: minMonthly});
    this.setState({maxMonthly: maxMonthly});
    this.setState({avgMonthly: avgMonthly});
    this.props.onMonthlyExpenseUpdate(avgMonthly/1000.);
    
    // console.log("Min Monthly: ", this.state.minMonthly, ", Max Monthly: ", this.state.maxMonthly, ", Avg Monthly: ", this.state.avgMonthly);
    console.log("Monthly Expense: ", this.props.monthlyExpense)
    // alertService.success('Updated...', {autoClose:true} )

  }

  onDeleteWidgetRow = () => {
    var data = this.state.widgets;
    for (var i = data.length - 1; i >= 0; i--) {
      if (data[i].select) {
        data.splice(i, 1);
      }
    }
    this.setState({ widgets: data });
    document.getElementById('selectAll').indeterminate = false;
    document.getElementById('selectAll').checked = false;
  }

  // refreshes widget id's so they start at 1 and are consecutive 
  onRefreshWidgetId = () => {
    var data = this.state.widgets;
    for (var idx = 0; idx < this.state.widgets.length; idx++) {
      data[idx].id = idx + 1;
    }
    this.setState({ widgets: data })
  }

  // select all widgets
  onSelectAllWidgets = () => {
    var selectAllFlag = false;
    if (document.getElementById('selectAll').checked) {
      selectAllFlag = true;
    }
    var data = this.state.widgets;
    data.forEach((d) => d.select = selectAllFlag);
    this.setState({ widgets: data });
  }

  // changes widget state from contentEditable changes in Table
  onWidgetChange = (id, colName, e) => {
    // find row index from record id
    var minMonthly = 0;
    var maxMonthly = 0;
    var avgMonthly = 0;
    for (var i = 0; i < this.state.widgets.length; i++) {
      minMonthly += this.state.widgets[i].minVal;
      maxMonthly += this.state.widgets[i].maxVal;
      avgMonthly += (this.state.widgets[i].maxVal + this.state.widgets[i].minVal)/2.;
    }
    this.setState({minMonthly: minMonthly});
    this.setState({maxMonthly: maxMonthly});
    this.setState({avgMonthly: avgMonthly});
    this.props.onMonthlyExpenseUpdate(maxMonthly/1000.);

    var rowIndex;
    for (i = 0; i < this.state.widgets.length; i++) {
      if (this.state.widgets[i].id === parseInt(id)) {
        rowIndex = i;
        break;
      }
    }

    console.log("Min Monthly: ", this.state.minMonthly, ", Max Monthly: ", this.state.maxMonthly, ", Avg Monthly: ", this.state.avgMonthly);
    // alertService.success('Updated...', {autoClose:true} )
    var data = this.state.widgets;
    // update appropriate parameter
    if (colName === 'select') {
      data[rowIndex].select = !data[rowIndex].select;
    } else if (colName === 'name') {
      if (data[rowIndex][colName] === e.currentTarget.textContent) {
        return;
      }
      data[rowIndex][colName] = e.currentTarget.textContent;
    } else {
      if (e.currentTarget.textContent === '' || data[rowIndex][colName] === parseFloat(e.currentTarget.textContent)) {
        return;
      }
      let val = parseFloat(e.currentTarget.textContent)
      data[rowIndex][colName] = val;
    }
    // determine selectAll property
    var ctr = 0;
    data.forEach((d) => {
      if (d.select) ctr ++;
    })
    document.getElementById('selectAll').indeterminate = false;
    if (ctr === data.length) {
      document.getElementById('selectAll').checked = true;
    } else if (ctr > 0) {
      document.getElementById('selectAll').indeterminate = true;
    } else {
      document.getElementById('selectAll').checked = false;
    }
    // add REST call here to update table cell changes
    this.setState({ widgets: data });
  }

  // prevents new line from being added to table cell during edit
  preventNewLine = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
    }
  }
  

  // componentDidMount = () => {
  //   this.onUpdateWidgetRow()
  //   // this.onUpdateWidgetRow()
  //   // this.onUpdateWidgetRow()
  // }

  render() {
    return (
      <>
        <Row>
          <Col md={1} sm={1} xs={1} className="mb-3"></Col>
            <Button color='primary' size='sm' onClick={this.onInsertWidgetRow} ><MdAddBox />&nbsp; Insert</Button>{' '}
            <Button color='info' size='sm' onClick={this.onRefreshWidgetId} ><MdRefresh />&nbsp; Reset</Button>{' '}
            <Button color='danger' size='sm' onClick={this.onDeleteWidgetRow} ><MdDeleteForever />&nbsp; Delete</Button>{' '}
            <Button color='info' size='sm' onClick={this.onUpdateWidgetRow} ><MdRefresh />&nbsp; Update</Button>
          <Col md={1} sm={1} xs={1} className="mb-3"></Col>        
        </Row>

        <Row>
          <Col>
            <Card>
              <Table>
                <thead>
                  <tr>
                    {/* map column dictionary to table column headers */}
                    {this.state.widgetColumns.map(col =>
                      col.name === 'select' ?
                        <th key={col.name} style={col.style}>
                          <Input type='checkbox' id='selectAll'
                            style={{ margin: 'auto', marginTop: '0.35em' }}
                            onClick={this.onSelectAllWidgets}
                          />
                        </th> :
                        <th key={col.name} style={col.style}>{col.header}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {/* map widgets to table row columns */}
                  {/* First column 'select' is set to input type=checkbox */}
                  {/* Other columns except 'id' column is set to contentEditable=true */}
                  {this.state.widgets.map(row =>
                    <tr key={row.id}>
                      {this.state.widgetColumns.map(col =>
                        col.name === 'select' ?
                          <td key={col.name} >
                            <Input type='checkbox'
                              style={{ margin: 'auto', marginTop: '0.35em' }}
                              onChange={(evt) => this.onWidgetChange(row.id, col.name, evt)}
                              checked={row[col.name]}
                            />
                          </td> :
                          <td key={col.name}
                            style={{ textAlign: 'center' }}
                            contentEditable={col.name !== 'id' ? true : false}
                            suppressContentEditableWarning={true}
                            onKeyDown={this.preventNewLine}
                            onBlur={(evt) => this.onWidgetChange(row.id, col.name, evt)}
                          >
                            {row[col.name]}
                          </td>
                      )}
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>

      </>
    )
  }
}

export default MonthlyExpense;

