import React from "react";
import { connect } from "react-redux";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import firebase from 'firebaseConfig';

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardImg,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";


import { AuthContext } from "../AuthContext";
import NotificationAlert from "react-notification-alert";
import {loginAction, loginActionGoogle} from "../actions/loginAction"; //updateGoogleAction
import registerAction from "../actions/registerAction";


const responseGoogle = (response) => {
    console.log(response);
}

const storage = firebase.storage().ref()


// var storageRef = storage.ref('images/nasdaq_kmeans_10_map.png');

class Home extends React.Component {

  state = {
    name: "",
    email: "",
    password: ""
  };
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: "data1",
      nasdaq_pci2_kmeans10_pscore_map_group_2:'', nasdaq_kmeans_10_map:''
    };
  //   console.log("Inside the setup.");
  //   storage.child('images/nasdaq_kmeans_10_map.png').getDownloadURL().then(function(url) {
      
  //   // This can be downloaded directly:
  //   var xhr = new XMLHttpRequest();
  //   xhr.responseType = 'blob';
  //   xhr.onload = function(event) {
  //   var blob = xhr.response;
  //   };
  //   xhr.open('GET', url);
  //   xhr.send();

  //   // Or inserted into an <img> element:
  //   var img = document.getElementById('myimg');
  //   img.src = url;
  //   }).catch(function(error) {
  //     console.log(error)
  // // Handle any errors
  // });

}

  // getImage(image) {  
  // let { state } = this  
  //   console.log(image)
  //   storage.child('images').child('${image}.png').getDownloadURL().then((url) => 
  //       {
  //         state[image] = url;
  //         console.log(url);
  //         //setState(this.state[image] = url;
  //         this.setState(state);//#{image:url});
  //  var xhr = new XMLHttpRequest();
		//   xhr.responseType = 'blob';
		//   xhr.onload = function(event) {
		// 	      var blob = xhr.response;
		// 	    };
		//   xhr.open('GET', url);
		//   xhr.send();

		//   // Or inserted into an <img> element:
		//    var img = document.getElementById('myimg');
		//      img.src = url;
		//      }).catch(function(error) {
		//        // Handle any errors
		//     console.log(error);
		//        });
  //      }



  setBgChartData = name => {
    this.setState({
      bigChartData: name
    });

    }

  render() {
    return (
      <>
        <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>

        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  registerAction: (name, email, password) =>
    dispatch(registerAction(name, email, password)),
    loginAction: (email, password) => dispatch(loginAction(email, password)),
    loginActionGoogle: () => dispatch(loginActionGoogle())

});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);


          // <Row>
          //   <Col lg="4">
          //     <Card className="card-chart">
          //       <CardHeader>
          //         <h5 className="card-category">Total Shipments</h5>
          //         <CardTitle tag="h3">
          //           <i className="tim-icons icon-bell-55 text-info" />{" "}
          //           763,215
          //         </CardTitle>
          //       </CardHeader>
          //       <CardBody>
          //         <div className="chart-area">
          //         {//<img src='' alt="Nasdaq" id='myimg' />
          //           }
          //           <Line
          //             data={chartExample2.data}
          //             options={chartExample2.options}
          //           />
          //         </div>
          //       </CardBody>
          //     </Card>
          //   </Col>
          //   <Col lg="4">
          //     <Card className="card-chart">
          //       <CardHeader>
          //         <h5 className="card-category">Daily Sales</h5>
          //         <CardTitle tag="h3">
          //           <i className="tim-icons icon-delivery-fast text-primary" />{" "}
          //           3,500€
          //         </CardTitle>
          //       </CardHeader>
          //       <CardBody>
          //         <div className="chart-area">
          //           <Bar
          //             data={chartExample3.data}
          //             options={chartExample3.options}
          //           />
          //         </div>
          //       </CardBody>
          //     </Card>
          //   </Col>
          //   <Col lg="4">
          //     <Card className="card-chart">
          //       <CardHeader>
          //         <h5 className="card-category">Completed Tasks</h5>
          //         <CardTitle tag="h3">
          //           <i className="tim-icons icon-send text-success" /> 12,100K
          //         </CardTitle>
          //       </CardHeader>
          //       <CardBody>
          //         <div className="chart-area">
          //           <Line
          //             data={chartExample4.data}
          //             options={chartExample4.options}
          //           />
          //         </div>
          //       </CardBody>
          //     </Card>
          //   </Col>
          // </Row>
          // <Row>
          //   {
            // <Col lg="6" md="12">
              // <Card className="card-tasks">
              //   <CardHeader>
              //     <h6 className="title d-inline">Tasks(5)</h6>
              //     <p className="card-category d-inline"> today</p>
              //     <UncontrolledDropdown>
              //       <DropdownToggle
              //         caret
              //         className="btn-icon"
              //         color="link"
              //         data-toggle="dropdown"
              //         type="button"
              //       >
              //         <i className="tim-icons icon-settings-gear-63" />
              //       </DropdownToggle>
              //       <DropdownMenu aria-labelledby="dropdownMenuLink" right>
              //         <DropdownItem
              //           href="#digitizeme"
              //           onClick={e => e.preventDefault()}
              //         >
              //           Action
              //         </DropdownItem>
              //         <DropdownItem
              //           href="#digitizeme"
              //           onClick={e => e.preventDefault()}
              //         >
              //           Another action
              //         </DropdownItem>
              //         <DropdownItem
              //           href="#digitizeme"
              //           onClick={e => e.preventDefault()}
              //         >
              //           Something else
              //         </DropdownItem>
              //       </DropdownMenu>
              //     </UncontrolledDropdown>
              //   </CardHeader>
              //   <CardBody>
              //     <div className="table-full-width table-responsive">
              //       <Table>
              //         <tbody>
              //           <tr>
              //             <td>
              //               <FormGroup check>
              //                 <Label check>
              //                   <Input defaultValue="" type="checkbox" />
              //                   <span className="form-check-sign">
              //                     <span className="check" />
              //                   </span>
              //                 </Label>
              //               </FormGroup>
              //             </td>
              //             <td>
              //               <p className="title">Update the Documentation</p>
              //               <p className="text-muted">
              //                 Dwuamish Head, Seattle, WA 8:47 AM
              //               </p>
              //             </td>
              //             <td className="td-actions text-right">
              //               <Button
              //                 color="link"
              //                 id="tooltip636901683"
              //                 title=""
              //                 type="button"
              //               >
              //                 <i className="tim-icons icon-pencil" />
              //               </Button>
              //               <UncontrolledTooltip
              //                 delay={0}
              //                 target="tooltip636901683"
              //                 placement="right"
              //               >
              //                 Edit Task
              //               </UncontrolledTooltip>
              //             </td>
              //           </tr>
              //           <tr>
              //             <td>
              //               <FormGroup check>
              //                 <Label check>
              //                   <Input
              //                     defaultChecked
              //                     defaultValue=""
              //                     type="checkbox"
              //                   />
              //                   <span className="form-check-sign">
              //                     <span className="check" />
              //                   </span>
              //                 </Label>
              //               </FormGroup>
              //             </td>
              //             <td>
              //               <p className="title">GDPR Compliance</p>
              //               <p className="text-muted">
              //                 The GDPR is a regulation that requires businesses
              //                 to protect the personal data and privacy of Europe
              //                 citizens for transactions that occur within EU
              //                 member states.
              //               </p>
              //             </td>
              //             <td className="td-actions text-right">
              //               <Button
              //                 color="link"
              //                 id="tooltip457194718"
              //                 title=""
              //                 type="button"
              //               >
              //                 <i className="tim-icons icon-pencil" />
              //               </Button>
              //               <UncontrolledTooltip
              //                 delay={0}
              //                 target="tooltip457194718"
              //                 placement="right"
              //               >
              //                 Edit Task
              //               </UncontrolledTooltip>
              //             </td>
              //           </tr>
              //           <tr>
              //             <td>
              //               <FormGroup check>
              //                 <Label check>
              //                   <Input defaultValue="" type="checkbox" />
              //                   <span className="form-check-sign">
              //                     <span className="check" />
              //                   </span>
              //                 </Label>
              //               </FormGroup>
              //             </td>
              //             <td>
              //               <p className="title">Solve the issues</p>
              //               <p className="text-muted">
              //                 Fifty percent of all respondents said they would
              //                 be more likely to shop at a company
              //               </p>
              //             </td>
              //             <td className="td-actions text-right">
              //               <Button
              //                 color="link"
              //                 id="tooltip362404923"
              //                 title=""
              //                 type="button"
              //               >
              //                 <i className="tim-icons icon-pencil" />
              //               </Button>
              //               <UncontrolledTooltip
              //                 delay={0}
              //                 target="tooltip362404923"
              //                 placement="right"
              //               >
              //                 Edit Task
              //               </UncontrolledTooltip>
              //             </td>
              //           </tr>
              //           <tr>
              //             <td>
              //               <FormGroup check>
              //                 <Label check>
              //                   <Input defaultValue="" type="checkbox" />
              //                   <span className="form-check-sign">
              //                     <span className="check" />
              //                   </span>
              //                 </Label>
              //               </FormGroup>
              //             </td>
              //             <td>
              //               <p className="title">Release v2.0.0</p>
              //               <p className="text-muted">
              //                 Ra Ave SW, Seattle, WA 98116, SUA 11:19 AM
              //               </p>
              //             </td>
              //             <td className="td-actions text-right">
              //               <Button
              //                 color="link"
              //                 id="tooltip818217463"
              //                 title=""
              //                 type="button"
              //               >
              //                 <i className="tim-icons icon-pencil" />
              //               </Button>
              //               <UncontrolledTooltip
              //                 delay={0}
              //                 target="tooltip818217463"
              //                 placement="right"
              //               >
              //                 Edit Task
              //               </UncontrolledTooltip>
              //             </td>
              //           </tr>
              //           <tr>
              //             <td>
              //               <FormGroup check>
              //                 <Label check>
              //                   <Input defaultValue="" type="checkbox" />
              //                   <span className="form-check-sign">
              //                     <span className="check" />
              //                   </span>
              //                 </Label>
              //               </FormGroup>
              //             </td>
              //             <td>
              //               <p className="title">Export the processed files</p>
              //               <p className="text-muted">
              //                 The report also shows that consumers will not
              //                 easily forgive a company once a breach exposing
              //                 their personal data occurs.
              //               </p>
              //             </td>
              //             <td className="td-actions text-right">
              //               <Button
              //                 color="link"
              //                 id="tooltip831835125"
              //                 title=""
              //                 type="button"
              //               >
              //                 <i className="tim-icons icon-pencil" />
              //               </Button>
              //               <UncontrolledTooltip
              //                 delay={0}
              //                 target="tooltip831835125"
              //                 placement="right"
              //               >
              //                 Edit Task
              //               </UncontrolledTooltip>
              //             </td>
              //           </tr>
              //           <tr>
              //             <td>
              //               <FormGroup check>
              //                 <Label check>
              //                   <Input defaultValue="" type="checkbox" />
              //                   <span className="form-check-sign">
              //                     <span className="check" />
              //                   </span>
              //                 </Label>
              //               </FormGroup>
              //             </td>
              //             <td>
              //               <p className="title">Arival at export process</p>
              //               <p className="text-muted">
              //                 Capitol Hill, Seattle, WA 12:34 AM
              //               </p>
              //             </td>
              //             <td className="td-actions text-right">
              //               <Button
              //                 color="link"
              //                 id="tooltip217595172"
              //                 title=""
              //                 type="button"
              //               >
              //                 <i className="tim-icons icon-pencil" />
              //               </Button>
              //               <UncontrolledTooltip
              //                 delay={0}
              //                 target="tooltip217595172"
              //                 placement="right"
              //               >
              //                 Edit Task
              //               </UncontrolledTooltip>
              //             </td>
              //           </tr>
              //         </tbody>
              //       </Table>
              //     </div>
              //   </CardBody>
              // </Card>
            // </Col>
            // <Col lg="6" md="12">
              // <Card>
              //   <CardHeader>
              //     <CardTitle tag="h4">Simple Table</CardTitle>
              //   </CardHeader>
              //   <CardBody>
              //     <Table className="tablesorter" responsive>
              //       <thead className="text-primary">
              //         <tr>
              //           <th>Name</th>
              //           <th>Country</th>
              //           <th>City</th>
              //           <th className="text-center">Salary</th>
              //         </tr>
              //       </thead>
              //       <tbody>
              //         <tr>
              //           <td>Dakota Rice</td>
              //           <td>Niger</td>
              //           <td>Oud-Turnhout</td>
              //           <td className="text-center">$36,738</td>
              //         </tr>
              //         <tr>
              //           <td>Minerva Hooper</td>
              //           <td>Curaçao</td>
              //           <td>Sinaai-Waas</td>
              //           <td className="text-center">$23,789</td>
              //         </tr>
              //         <tr>
              //           <td>Sage Rodriguez</td>
              //           <td>Netherlands</td>
              //           <td>Baileux</td>
              //           <td className="text-center">$56,142</td>
              //         </tr>
              //         <tr>
              //           <td>Philip Chaney</td>
              //           <td>Korea, South</td>
              //           <td>Overland Park</td>
              //           <td className="text-center">$38,735</td>
              //         </tr>
              //         <tr>
              //           <td>Doris Greene</td>
              //           <td>Malawi</td>
              //           <td>Feldkirchen in Kärnten</td>
              //           <td className="text-center">$63,542</td>
              //         </tr>
              //         <tr>
              //           <td>Mason Porter</td>
              //           <td>Chile</td>
              //           <td>Gloucester</td>
              //           <td className="text-center">$78,615</td>
              //         </tr>
              //         <tr>
              //           <td>Jon Porter</td>
              //           <td>Portugal</td>
              //           <td>Gloucester</td>
              //           <td className="text-center">$98,615</td>
              //         </tr>
              //       </tbody>
              //     </Table>
              //   </CardBody>
              // </Card>
            // </Col>