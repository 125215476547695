import React, { Component, useState, useEffect, useRef} from "react";

import { Line } from "react-chartjs-2";

// const ctx = canvas.getContext("2d"); const gradient = ctx.createLinearGradient(0, 0, 0, height); gradient.addColorStop(0, 'rgba(33, 150, 243, 0.56)');  gradient.addColorStop(1, 'rgba(33, 150, 243, 0.04)');  lineColor: 'rgba(33, 150, 243, 1)',

// const canvasRef = useRef(null);

export default class MyChart extends Component {

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext("2d");
  // });


  chartReference = null;

  constructor(props) {
    super(props);
    this.state = {
      ...props,
    }
    this.props.onDrawdownUpdate(this.props.data[this.props.data.length-1].maxDrawdown);

  }
  
  render() {

  const data = {

      labels: this.props.data.map((obj) => {
        return obj.month;
      }), 

      datasets: [
        {
          label: "Value in Thousands",
          yAxisID: "A",          
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          // borderColor: "#2B2B43", //"rgba(45,242,162,1)",
          borderColor: 'rgba(33, 150, 243, 1)', //"rgba(45,242,162,1)",
          borderWidth: 2,
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.props.data.map((obj) => obj.worth/1000)
        },
        {
          label: "User Count",
          yAxisID: "B",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.props.data.map(obj => obj.users)
        }
      ]
    };

    return (
        <Line
          data={data}
          options={ { scales: {
            xAxes: [{ scaleLabel: { labelString: "Months", display: true, color: "white" } }],
            yAxes: [
            { id: "A", display: true,  type: "linear", position: "right", 
              scaleLabel: { labelString: "Thousands", display: true, color: "white" },
              ticks: { beginAtZero: true, min: -2000, max: 2000 } },
            { id: "B", display: true,  type: "linear", position: "left", 
              scaleLabel: { labelString: "Users", display: true, color: "white" },
              ticks: { beginAtZero: true, min: -150000, max: 150000 } }
            ]
           } } }
           
        />
    );
  }

  componentDidMount() {
    //const { datasets } = this.refs.chart.chartInstance.data;
    // console.log(datasets[0].data);
  }

  componentWillUpdate() {
  //   if (typeof chartReference !== "undefined") {
  //     this.chartReference.destroy();
  //     // console.log("destroyed!");
  //   }
      // this.props.onDrawdownUpdate(this.props.data[this.props.data.length-1].maxDrawdown);
  }
}
