import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import ClientLayout from "layouts/Client/Client.js";
import PublicLayout from "layouts/Public/Public.js";
// import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";

import { Provider } from "react-redux";
import configureStore from "store";

// import * as serviceWorker from "./serviceWorker";
// import { ApolloProvider } from "react-apollo";
// import { ApolloClient } from "apollo-client";
// import { createHttpLink } from "apollo-link-http";
// import { onError } from "apollo-link-error";
// import { InMemoryCache } from "apollo-cache-inmemory";


//import { Provider } from "react-redux";
//import configureStore from "store";
//const store = configureStore();
const hist = createBrowserHistory();

// const getToken = () => {
//   const pureJSON = localStorage.getItem("auth_access_token");
//   const accessToken = JSON.parse(localStorage.getItem(pureJSON));
//   if (accessToken === null || accessToken === undefined) {
//     return false;
//   } else {
//     return true;
//   }
// };

// // 2
// const httpLink = createHttpLink({
//   // headers: !getToken()    ? ""    : { authorization: localStorage.getItem("kiu_auth_access_token") },
//   uri:
//     process.env.REACT_APP_APOLLO_URL ||
//     "http://digitizeme.net/graphql/",
//   request: async operation => {
//     // Get JWT token from local storage
//     const token = !getToken()
//       ? ""
//       : localStorage.getItem("auth_access_token");

//     // Pass token to headers
//     operation.setContext({
//       headers: {
//         Authorization: token ? `Bearer ${token}` : ""
//       }
//     });
//   }
// });

// const errorLink = onError(error => {
//   const { graphQLErrors = [], networkError = {}, operation = {}, forward } =
//     error || {};
//   // const { getContext } = operation || {};
//   // const { scope, headers = {} } = getContext() || {};
//   const { message: networkErrorMessage = "" } = networkError || {};
//   const { message: graphQLErrorsMessage = "" } = graphQLErrors || [];
//   const graphQLFailed = message =>
//     typeof message === "string" && message.startsWith("Problem with graphql");
//   const networkFailed = message =>
//     typeof message === "string" &&
//     message.startsWith("Network Error when attempting to fetch resource");

//   if (graphQLFailed(graphQLErrorsMessage)) return forward(operation);
//   if (networkFailed(networkErrorMessage)) return forward(operation);
// });

// const link = errorLink.concat(httpLink);

// // 3
// const client = new ApolloClient({
//   link,
//   cache: new InMemoryCache()
// });

// 4
const rootElement = document.getElementById("root");

const store = configureStore();

//const firebaseUser = JSON.parse(localStorage.getItem("firebaseUser"))
ReactDOM.render(
  <Provider store={store}>
      <React.StrictMode>
		  <Router history={hist}>
		    <Switch>
		      <Route path="/client" render={props => <ClientLayout {...props} />} />
		      <Route path="/public" render={props => <PublicLayout {...props} />} />
		      <Redirect from="/" to="/public/home" />
		    </Switch>
		  </Router>,
	  </React.StrictMode>,
    </Provider>,
  rootElement
);
//<Route path="/rtl" render={props => <RTLLayout {...props} />} />

//serviceWorker.register();
// <ApolloProvider client={client}>
// </ApolloProvider>,
