import React from "react";
import ChartSettings from "components/ChartSettings";
import { connect } from "react-redux";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { Line, Bar } from "react-chartjs-2";
// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";

import MyChart from "components/my_chart";
import plan from "components/plan";
import Payroll from "components/PayrollTable"
import MonthlyExpense from "components/MonthlyExpenseTable"
import OneTimeExpense from "components/OneTimeExpenseTable"

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

class Budget extends React.Component {

  constructor(props){
        super(props);

        this.state = {
          ...props,
            adPrice: 10,
            expense: 200,
            userCount: 200,
            devMonths: 5,
            monthsToGainCustomers: 21,
            growth: 15,
            adsPerHour: 2,
            hoursPerDay: 1 ,
            payrollExpense: 100, // in thousands
            monthlyExpense: 14.75, // in thousands
            oneTimeExpense: 30, // in thousands
            maxDrawdown: 100000,
        };
        this.onDevMonthsChange = this.onDevMonthsChange.bind(this);
        this.onGrowthChange = this.onGrowthChange.bind(this);
        this.onAdPriceChange = this.onAdPriceChange.bind(this);
        this.onMonthsToGainCustomersChange = this.onMonthsToGainCustomersChange.bind(this);
        this.onAdsPerHourChange = this.onAdsPerHourChange.bind(this);
        this.onHoursPerDayChange = this.onHoursPerDayChange.bind(this);
        this.onExpenseChange = this.onExpenseChange.bind(this);
        this.onUserCountChange = this.onUserCountChange.bind(this);        
        this.onPayrollUpdate = this.onPayrollUpdate.bind(this);
        this.onMonthlyExpenseUpdate = this.onMonthlyExpenseUpdate.bind(this);
        this.onOneTimeExpenseUpdate = this.onOneTimeExpenseUpdate.bind(this);
        this.onDrawdownUpdate = this.onDrawdownUpdate.bind(this);
        this.getChartData = this.getChartData.bind(this);
        this.calculateDrawDown = this.calculateDrawDown.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
 }

 componentDidMount() {
    window.addEventListener('load', this.handleLoad);
 }

 componentWillUnmount() { 
   window.removeEventListener('load', this.handleLoad)  
 }

 handleLoad() {
  // $("myclass") //  $ is available here
  this.calculateDrawDown()
 }

  onDevMonthsChange(value) {
    this.setState({devMonths:value});
    this.calculateDrawDown()
  }

  onGrowthChange(value) {
    this.setState({growth : value});
    this.calculateDrawDown()
  }

  onAdPriceChange(value) {
    this.setState({adPrice : value});
    this.calculateDrawDown()
  }

  onMonthsToGainCustomersChange(value) {
    this.setState({monthsToGainCustomers : value});
    this.calculateDrawDown()
  }

  onAdsPerHourChange(event) {
    this.setState({adsPerHour : event.target.value});
    this.calculateDrawDown()
  }

  onHoursPerDayChange(event) {
    this.setState({hoursPerDay : event.target.value});
    this.calculateDrawDown()
  }

  onExpenseChange(event) {
    this.setState({expense : event.target.value});
    // this.setState({monthlyExpense : event.target.value});
    // var updatedExpense = this.state.monthlyExpense + this.state.payrollExpense// + this.state.oneTimeExpense;
    // this.setState({expense : updatedExpense});
    this.calculateDrawDown()
  }

  onDrawdownUpdate(value) {
    this.setState({maxDrawdown : value});
  }

  onPayrollUpdate(value) {
    this.setState({payrollExpense : value});
    var updatedExpense = this.state.monthlyExpense + this.state.payrollExpense// + this.state.oneTimeExpense;
    this.setState({expense : updatedExpense});
    this.calculateDrawDown()
    // console.log("Budget Expense from payrollExpense: ", this.state.expense, ", monthlyExpense: ", this.state.monthlyExpense, ", payrollExpense: ", this.state.payrollExpense, ", oneTimeExpense:", this.state.oneTimeExpense);
  }

  onMonthlyExpenseUpdate(value) {
    this.setState({monthlyExpense : value});
    var updatedExpense = this.state.monthlyExpense + this.state.payrollExpense// + this.state.oneTimeExpense;
    this.setState({expense : updatedExpense});
    this.calculateDrawDown()
    // console.log("Budget Expense from payrollExpense: ", this.state.expense, ", monthlyExpense: ", this.state.monthlyExpense, ", payrollExpense: ", this.state.payrollExpense, ", oneTimeExpense:", this.state.oneTimeExpense);
  }


  onOneTimeExpenseUpdate(value) {
    this.setState({oneTimeExpense : value});
    var updatedExpense = this.state.monthlyExpense + this.state.payrollExpense //+ this.state.oneTimeExpense;
    this.setState({expense : updatedExpense});
    this.calculateDrawDown()
    // console.log("Budget Expense from payrollExpense: ", this.state.expense, ", monthlyExpense: ", this.state.monthlyExpense, ", payrollExpense: ", this.state.payrollExpense, ", oneTimeExpense:", this.state.oneTimeExpense);
  }


  onUserCountChange(event) {
    this.setState({userCount : event.target.value});
    this.calculateDrawDown()
  }

  showAlert(type, message) {
    this.setState({
      alert: true,
      alertData: { type, message }
    });
    setTimeout(() => {
      this.setState({ alert: false });
    }, 4000)
  }

  // componentDidMount = () => {
  //   this.onUpdateWidgetRow()
  //   this.onUpdateWidgetRow()
  //   this.onUpdateWidgetRow()
  // }

  getChartData() {
    //<>{var chartData = this.getChartData()} </>
    var data = plan(this.state)
    //console.log(data)
    // let maxDrawdown = 0
    // for (var i =0; i < data.length; i++){
    //   if (data[i].worth < maxDrawdown) { maxDrawdown = data[i].worth; }
    // }
    // this.onDrawdownUpdate(maxDrawdown)
    // this.setState({ maxDrawdown: data[data.length-1 ].maxDrawdown });
    return data;
  }

  calculateDrawDown() {
      var current_month = 0;
      var current_value = -1 * this.state.oneTimeExpense * 1000;
      var user_count = 0;
      var maxDD = 0
         
      for (var i = 0; i < this.state.monthsToGainCustomers; i++) {
        var revenue = 0
        if (i > 0) {
            current_value -= this.state.expense * 1000;
            if (i >= this.state.devMonths + 2)  {
              if (user_count === 0) user_count = 15000
              user_count = user_count * (1+(this.state.growth * 0.01))  
              revenue = user_count * this.state.adPrice * this.state.adsPerHour * this.state.hoursPerDay * 20
              current_value += revenue
            }
            if (current_value < maxDD) { maxDD = current_value; }
          }    
        // console.log(i, current_value, this.state.expense * 1000, maxDD)
      }

      this.setState({ maxDrawdown: maxDD });
  }
  
  render() {
    return (
      <>
        <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>        
          <Row>


            <Col md="12">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Interactive</h5>
                  <CardTitle tag="h3">
                  Expense / Monitization

                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div> 
                       
                      <MyChart
                        data={plan(this.state)}
                        options={chartExample4.options}
                        maxDrawdown={this.state.maxDrawdown}
                        onDrawdownUpdate={this.onDrawdownUpdate}
                      />
                      <center> 
                      User growth calculations assume that 15,000 users are gained in the 3 months after launch, and advertising sales begin at {this.state.devMonths + 3} months.<br/>
                      This configuration forcasts a maximum drawdown of {(this.state.maxDrawdown).toLocaleString('en-US', { style: 'currency', currency: 'USD', })}.
                          </center> 
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>


        <Col md="1"/>
            <Col md="10">
              <Card>
                <CardHeader>
                    <CardTitle tag="h4">Settings</CardTitle>
                </CardHeader>
                <CardBody>
                    <ChartSettings 
                      onUserCountChange={this.onUserCountChange}
                      onExpenseChange={this.onExpenseChange} 
                      onHoursPerDayChange={this.onHoursPerDayChange}
                      onAdsPerHourChange={this.onAdsPerHourChange}
                      onMonthsToGainCustomersChange={this.onMonthsToGainCustomersChange}
                      onAdPriceChange={this.onAdPriceChange}
                      onGrowthChange={this.onGrowthChange}
                      onDevMonthsChange={this.onDevMonthsChange}

                      userCount={this.state.userCount}
                      expense={this.state.expense} 
                      hoursPerDay={this.state.hoursPerDay}
                      adsPerHour={this.state.adsPerHour}
                      monthsToGainCustomers={this.state.monthsToGainCustomers}
                      adPrice={this.state.adPrice}
                      growth={this.state.growth}
                      devMonths={this.state.devMonths}

                    />
                </CardBody>
              </Card>
            </Col>
          <Col md="1"/>
          </Row>
          <Row>
            <Col md="6">
              <Card className="mb-3">
                <CardHeader>
                    <CardTitle style={{ color: '#6a82fb', fontWeight: 600 }}>HR Budget Configuration</CardTitle>
                </CardHeader>
                <CardBody>
                    <Payroll    
                        payrollExpense={this.state.payrollExpense}
                        onPayrollUpdate={this.onPayrollUpdate} 
                     />
                </CardBody>
              </Card>
            </Col>

            <Col md="6">
              <Row>
                <Card className="mb-3">
                  <CardHeader>
                      <CardTitle style={{ color: '#6a82fb', fontWeight: 600 }}>Monthly Expenses</CardTitle>
                  </CardHeader>
                  <CardBody>
                      <MonthlyExpense    
                          monthlyExpense={this.state.monthlyExpense}
                          onMonthlyExpenseUpdate={this.onMonthlyExpenseUpdate} 
                       />
                  </CardBody>
                </Card>
              </Row>

              <Row>
                <Card className="mb-3">
                  <CardHeader>
                      <CardTitle style={{ color: '#6a82fb', fontWeight: 600 }}>One-Time Expenses</CardTitle>
                  </CardHeader>
                  <CardBody>
                      <OneTimeExpense    
                          oneTimeExpense={this.state.oneTimeExpense}
                          onOneTimeExpenseUpdate={this.onOneTimeExpenseUpdate} 
                       />
                  </CardBody>
                </Card>              
              </Row>

            </Col>

          </Row>

        </div>
      </>
    );
  }
}

// export default Budget;
const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Budget);
