import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {loginAction, loginActionGoogle, updateGoogleAction} from "actions/loginAction";
import {firebaseAuth} from 'firebaseConfig'; 

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Alert,
  UncontrolledAlert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

const Spacer = require('react-spacer')

class Login extends React.Component {
  firebaseUser = JSON.parse(localStorage.getItem("firebaseUser"))

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
  };


  constructor(props) {
      super(props);
      this.state = {...props, email: "", password: "" }
    }
  

  notify = place => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            Welcome to <b>Black Dashboard React</b> - a beautiful freebie for
            every web developer.
          </div>
        </div>
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  componentWillMount()    {
      if (this.state.authState.loggedIn === undefined) { 
        console.log("Authstate unconfirmed")
        if (localStorage.getItem("appToken") !== undefined) {
          console.log("appToken found")
          try {
            const firebaseUser = localStorage.getItem("firebaseUser")
            this.setState({authState: {loggedIn:true, user:firebaseUser } });
          } catch(e) { console.error(e); console.log("unable to restore from localStorage")}
        }
      } else { console.log("Authstate confirmed") }
  
      firebaseAuth().onAuthStateChanged(user => {
          if (user) {
             // updateGoogleAction()
              console.log("User signed in: ", JSON.stringify(user.displayName));
              // localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("appToken", user.uid);
              this.setState({authState: {loggedIn:true, user:user} });
              // this.props.history.push("/client/profile")//, this.state)
          }
      });
   }   
   componentDidUpdate() {
      if (this.state.authState.loggedIn === undefined) { 
        console.log("Authstate unconfirmed")
        if (localStorage.getItem("appToken") !== undefined) {
          console.log("appToken found")
          try {
            const firebaseUser = localStorage.getItem("firebaseUser")
            this.setState({authState: {loggedIn:true, user:firebaseUser } });
          } catch(e) { console.error(e); console.log("unable to restore from localStorage")}
        }
      } else { console.log("Authstate confirmed") }
  
      firebaseAuth().onAuthStateChanged(user => {
          if (user) {
             // updateGoogleAction()
              console.log("User signed in: ", JSON.stringify(user.displayName));
              // localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("appToken", user.uid);
              this.setState({authState: {loggedIn:true, user:user} });
          }
      }); 
      this.props.history.push("/client/profile")
   
   }
  render() {
   //  if (
   //    //(!localStorage.getItem("appToken")===null) |
   //   (this.props.authState.loggedIn) & 
   // (this.firebaseUser))
   //    {
   //      this.props.history.push("/client/profile");
   //      return <Redirect to="/client/profile" />;

   //    }    
    // if (this.props.authState.loggedIn) { 
    //     this.props.history.push("/client/profile"); 
    //   return <Redirect to="/client/profile" /> 
    // }
      if ( (this.props.authState.loggedIn) | (this.firebaseUser) ) {
        this.props.history.push("/client/profile"); 
      return <Redirect to="/client/profile" /> 
      }

      return (
      <>

        <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <Row>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Login Options</CardTitle>
                </CardHeader>
                <CardBody>
                <div style={{ display:'flex'}}>

                    <div> Click here to sign-in using Google </div>  
                  <Spacer grow='1'/>
                  <div>
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      onClick= {() => 
                        {this.props.loginActionGoogle(); } //this.props.updateGoogleAction();}
                       }>  
                      <span className="btn-inner--icon">

                        <img
                          alt="..."
                          src={require("../assets/img/google.svg")}
                        />
                      </span>
                      <span className="btn-inner--text">Google</span>
                    </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Register</CardTitle>
                </CardHeader>
                <CardBody>
                
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
// }
const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  updateGoogleAction: () => dispatch(updateGoogleAction()),
  loginAction: (email, password) => dispatch(loginAction(email, password)),
  loginActionGoogle: () => dispatch(loginActionGoogle())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
