// import Page from './Page';
import React, { Component } from 'react';
import { MdAddBox, MdDeleteForever, MdRefresh } from 'react-icons/md';
import {
  Card,
  Col,
  Row,
  Button,
  Table,
  Input
} from 'reactstrap';

// import { alertService } from '../services';

var defaultWidget = { select: false, id: 0, name: 'Position', maxVal: 1000, minVal: 1000, category: 'Job Category', subCategory: 'Job Sub-Category', payType: 'freelance', monthsNeeded: '6' }

var widgetData = [
  { select: false, id: 1, name: 'Operations', maxVal: 12000, minVal: 10000, category: 'Corp', subCategory: 'Org', payType: 'full time', monthsNeeded: 0 },
  { select: false, id: 1, name: 'Business Relations', maxVal: 12000, minVal: 10000, category: 'Corp', subCategory: 'Org', payType: 'full time', monthsNeeded: 0 },
  { select: false, id: 2, name: 'HR Consulting', maxVal: 3000, minVal: 3000, category: 'Corp', subCategory: 'Org', payType: 'full time', monthsNeeded: 2 },
  { select: false, id: 3, name: 'Job Admin', maxVal: 5000, minVal: 3000, category: 'Corp', subCategory: 'Org', payType: 'full time', monthsNeeded: 0 },
  { select: false, id: 4, name: 'Web Dev Lead', maxVal: 2500, minVal: 2000, category: 'Dev', subCategory: 'Web', payType: 'task', monthsNeeded: 0 },
  { select: false, id: 5, name: 'Web Dev 2', maxVal: 2000, minVal: 2000, category: 'Dev', subCategory: 'Web', payType: 'task', monthsNeeded: 0 },
  { select: false, id: 6, name: 'Web Dev 3', maxVal: 2000, minVal: 2000, category: 'Dev', subCategory: 'Web', payType: 'task', monthsNeeded: 0 },
  { select: false, id: 7, name: 'Engine Lead', maxVal: 6000, minVal: 3000, category: 'Dev', subCategory: '3D', payType: 'full time', monthsNeeded: 0 },
  { select: false, id: 8, name: 'Engine Dev 2', maxVal: 6000, minVal: 3000, category: 'Dev', subCategory: '3D', payType: 'full time', monthsNeeded: 0 },
  { select: false, id: 9, name: 'Engine Dev 3', maxVal: 6000, minVal: 3000, category: 'Dev', subCategory: '3D', payType: 'full time', monthsNeeded: 0 },
  { select: false, id: 8, name: 'Engine Social Integration', maxVal: 4000, minVal: 4000, category: 'Dev', subCategory: '3D', payType: 'task', monthsNeeded: 0 },
  { select: false, id: 9, name: 'Engine Trade Integrations', maxVal: 4000, minVal: 4000, category: 'Dev', subCategory: 'C++', payType: 'task', monthsNeeded: 0 },
  { select: false, id: 10, name: 'Broker API Integrations', maxVal: 4000, minVal: 4000, category: 'Dev', subCategory: 'C++', payType: 'task', monthsNeeded: 0 },
  { select: false, id: 10, name: 'Portfolio & Markets Integrations', maxVal: 4000, minVal: 4000, category: 'Dev', subCategory: 'C++/JS', payType: 'task', monthsNeeded: 0 },
  { select: false, id: 10, name: 'Stats & Analytics Integrations', maxVal: 4000, minVal: 4000, category: 'Dev', subCategory: 'C++/JS', payType: 'task', monthsNeeded: 0 },
  { select: false, id: 10, name: 'Data Lead / Admin', maxVal: 4000, minVal: 4000, category: 'Dev', subCategory: 'IT', payType: 'contract', monthsNeeded: 0 },
  { select: false, id: 10, name: 'AI Project Lead', maxVal: 14000, minVal: 10000, category: 'Dev', subCategory: 'AI/ML', payType: 'full time', monthsNeeded: 0 },
  { select: false, id: 10, name: 'AI Finance - 1', maxVal: 12000, minVal: 7000, category: 'Dev', subCategory: 'AI/ML', payType: 'full time', monthsNeeded: 0 },
  { select: false, id: 10, name: 'AI Finance - 2', maxVal: 12000, minVal: 7000, category: 'Dev', subCategory: 'AI/ML', payType: 'full time', monthsNeeded: 0 },
  { select: false, id: 10, name: 'Advertising Web Dev', maxVal: 4000, minVal: 4000, category: 'Dev', subCategory: 'Web', payType: 'task', monthsNeeded: 0 },
  { select: false, id: 10, name: 'Advertising Sales', maxVal: 2000, minVal: 2000, category: 'External', subCategory: 'Sales', payType: 'task', monthsNeeded: 0 },
  { select: false, id: 10, name: 'General Legal', maxVal: 5000, minVal: 2000, category: 'Corp', subCategory: 'Legal', payType: 'contract', monthsNeeded: 0 },
]

const widgetCols = [
  { header: '', name: 'select', style: { verticalAlign: 'top', width: '3em' } },
  { header: 'Id', name: 'id', style: { textAlign: 'center', verticalAlign: 'middle', width: '3em', color: '#6a82fb' } },
  { header: 'Name', name: 'name', style: { textAlign: 'center', verticalAlign: 'middle', width: '10em', color: '#6a82fb' } },
  { header: 'Min', name: 'minVal', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
  { header: 'Max', name: 'maxVal', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
  { header: 'Category', name: 'category', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
  { header: 'Sub-Category', name: 'subCategory', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
  { header: 'Pay Type', name: 'payType', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
  // { header: 'Months Needed', name: 'monthsNeeded', style: { textAlign: 'center', verticalAlign: 'middle', width: '15em', color: '#6a82fb' } },
];



class Payroll extends Component {

  constructor(props) {
    super(props);

    for (var i = 0; i < widgetData.length; i++) {
      widgetData[i]['id'] = i
    }

    this.state = {
      ...props,
      widgets: widgetData,
      widgetColumns: widgetCols,
      minMonthly: 0,
      maxMonthly: 0,
      avgMonthly: 0,
    }

    var minMonthly = 0;
    var maxMonthly = 0;
    var avgMonthly = 0;
    for (i = 0; i < this.state.widgets.length; i++) {
      minMonthly += this.state.widgets[i].minVal;
      maxMonthly += this.state.widgets[i].maxVal;
      avgMonthly += (this.state.widgets[i].maxVal + this.state.widgets[i].minVal)/2.;
    }
    this.setState({minMonthly: minMonthly});
    this.setState({maxMonthly: maxMonthly});
    this.setState({avgMonthly: avgMonthly});
    this.props.onPayrollUpdate(avgMonthly/1000.);
    // console.log("Min Payroll: ", this.state.minMonthly, ", Max Payroll: ", this.state.maxMonthly, ", Avg Payroll: ", this.state.avgMonthly);
    console.log("Payroll Expense: ", this.props.payrollExpense)

  }

  onInsertWidgetRow = () => {
    var lastWidgetId = 0;
    if (this.state.widgets.length > 0) {
      lastWidgetId = this.state.widgets[this.state.widgets.length - 1].id;
    }
    var newWidget = JSON.parse(JSON.stringify(defaultWidget));
    newWidget.id = lastWidgetId + 1;
    let data = this.state.widgets;
    data.push(newWidget);
    // can add REST call here to insert new record 
    this.setState({ widgets: data })
  }
  

  onUpdateWidgetRow = () => {
    var minMonthly = 0;
    var maxMonthly = 0;
    var avgMonthly = 0;
    for (var i = 0; i < this.state.widgets.length; i++) {
      minMonthly += this.state.widgets[i].minVal;
      maxMonthly += this.state.widgets[i].maxVal;
      avgMonthly += (this.state.widgets[i].maxVal + this.state.widgets[i].minVal)/2.;
    }
    this.setState({minMonthly: minMonthly});
    this.setState({maxMonthly: maxMonthly});
    this.setState({avgMonthly: avgMonthly});

    this.props.onPayrollUpdate(avgMonthly/1000.);

    console.log("Min Monthly: ", this.state.minMonthly, ", Max Monthly: ", this.state.maxMonthly, ", Avg Monthly: ", this.state.avgMonthly);

    // alertService.success('Updated...', {autoClose:true} )

  }

  onDeleteWidgetRow = () => {
    var data = this.state.widgets;
    for (var i = data.length - 1; i >= 0; i--) {
      if (data[i].select) {
        data.splice(i, 1);
      }
    }
    this.setState({ widgets: data });
    document.getElementById('selectAll').indeterminate = false;
    document.getElementById('selectAll').checked = false;
  }

  // refreshes widget id's so they start at 1 and are consecutive 
  onRefreshWidgetId = () => {
    var data = this.state.widgets;
    for (var idx = 0; idx < this.state.widgets.length; idx++) {
      data[idx].id = idx + 1;
    }
    this.setState({ widgets: data })
  }

  // select all widgets
  onSelectAllWidgets = () => {
    var selectAllFlag = false;
    if (document.getElementById('selectAll').checked) {
      selectAllFlag = true;
    }
    var data = this.state.widgets;
    data.forEach((d) => d.select = selectAllFlag);
    this.setState({ widgets: data });
  }

  // changes widget state from contentEditable changes in Table
  onWidgetChange = (id, colName, e) => {
    // find row index from record id
    var minMonthly = 0;
    var maxMonthly = 0;
    var avgMonthly = 0;
    for (var i = 0; i < this.state.widgets.length; i++) {
      minMonthly += this.state.widgets[i].minVal;
      maxMonthly += this.state.widgets[i].maxVal;
      avgMonthly += (this.state.widgets[i].maxVal + this.state.widgets[i].minVal)/2.;
    }
    this.setState({minMonthly: minMonthly});
    this.setState({maxMonthly: maxMonthly});
    this.setState({avgMonthly: avgMonthly});
    this.props.onPayrollUpdate(avgMonthly/1000.);

    var rowIndex;
    for (i = 0; i < this.state.widgets.length; i++) {
      if (this.state.widgets[i].id === parseInt(id)) {
        rowIndex = i;
        break;
      }
    }

    console.log("Min Monthly: ", this.state.minMonthly, ", Max Monthly: ", this.state.maxMonthly, ", Avg Monthly: ", this.state.avgMonthly);
    // alertService.success('Updated...', {autoClose:true} )
    var data = this.state.widgets;
    // update appropriate parameter
    if (colName === 'select') {
      data[rowIndex].select = !data[rowIndex].select;
    } else if (colName === 'name') {
      if (data[rowIndex][colName] === e.currentTarget.textContent) {
        return;
      }
      data[rowIndex][colName] = e.currentTarget.textContent;
    } else {
      if (e.currentTarget.textContent === '' || data[rowIndex][colName] === parseFloat(e.currentTarget.textContent)) {
        return;
      }
      let val = parseFloat(e.currentTarget.textContent)
      data[rowIndex][colName] = val;
    }
    // determine selectAll property
    var ctr = 0;
    data.forEach((d) => {
      if (d.select) ctr ++;
    })
    document.getElementById('selectAll').indeterminate = false;
    if (ctr === data.length) {
      document.getElementById('selectAll').checked = true;
    } else if (ctr > 0) {
      document.getElementById('selectAll').indeterminate = true;
    } else {
      document.getElementById('selectAll').checked = false;
    }
    // add REST call here to update table cell changes
    this.setState({ widgets: data });
  }

  // prevents new line from being added to table cell during edit
  preventNewLine = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
    }
  }

  render() {
      // <Page title="Data Config" breadcrumbs={[{ name: 'DataConfig', active: true }]}>
          // <Col md={1} sm={1} xs={1} className="mb-3"></Col>
          // <Col md={10} sm={10} xs={10} className="mb-3">
          //   <h3 style={{ color: '#6a82fb', fontWeight: 600 }}>HR Budget Configuration</h3>
          // </Col>
          // <Col md={1} sm={1} xs={1} className="mb-3"></Col>
        // <Row style={{ marginTop: '3em' }}>

    return (
      <>
        <Row>
          <Col md={1} sm={1} xs={1} className="mb-3"></Col>
            <Button color='primary' size='sm' onClick={this.onInsertWidgetRow} ><MdAddBox />&nbsp; Insert</Button>{' '}
            <Button color='info' size='sm' onClick={this.onRefreshWidgetId} ><MdRefresh />&nbsp; Reset</Button>{' '}
            <Button color='danger' size='sm' onClick={this.onDeleteWidgetRow} ><MdDeleteForever />&nbsp; Delete</Button>{' '}
            <Button color='info' size='sm' onClick={this.onUpdateWidgetRow} ><MdRefresh />&nbsp; Update</Button>
          <Col md={1} sm={1} xs={1} className="mb-3"></Col>        
        </Row>

        <Row>
          <Col>
            <Card>
              <Table>
                <thead>
                  <tr>
                    {/* map column dictionary to table column headers */}
                    {this.state.widgetColumns.map(col =>
                      col.name === 'select' ?
                        <th key={col.name} style={col.style}>
                          <Input type='checkbox' id='selectAll'
                            style={{ margin: 'auto', marginTop: '0.35em' }}
                            onClick={this.onSelectAllWidgets}
                          />
                        </th> :
                        <th key={col.name} style={col.style}>{col.header}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {/* map widgets to table row columns */}
                  {/* First column 'select' is set to input type=checkbox */}
                  {/* Other columns except 'id' column is set to contentEditable=true */}
                  {this.state.widgets.map(row =>
                    <tr key={row.id}>
                      {this.state.widgetColumns.map(col =>
                        col.name === 'select' ?
                          <td key={col.name} >
                            <Input type='checkbox'
                              style={{ margin: 'auto', marginTop: '0.35em' }}
                              onChange={(evt) => this.onWidgetChange(row.id, col.name, evt)}
                              checked={row[col.name]}
                            />
                          </td> :
                          <td key={col.name}
                            style={{ textAlign: 'center' }}
                            contentEditable={col.name !== 'id' ? true : false}
                            suppressContentEditableWarning={true}
                            onKeyDown={this.preventNewLine}
                            onBlur={(evt) => this.onWidgetChange(row.id, col.name, evt)}
                          >
                            {row[col.name]}
                          </td>
                      )}
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </>
    )
  }

}
      // </Page>
//           <Col md={1} sm={1} xs={1} className="mb-3"></Col>

export default Payroll;

