// import * as firebase from "firebase-tools";
//import * as firebase from "firebase-admin";
//const functions = require('firebase-functions');
// const firebase = require('firebase-admin')
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
import * as firebase from "firebase";

const config = {
   apiKey: "AIzaSyBRwUDIGqp7N0RBbyyI7pnrnKmqh-JFNgQ",
     authDomain: "digitizeme-com.firebaseapp.com",
       databaseURL: "https://digitizeme-com.firebaseio.com",
         projectId: "digitizeme-com",
           storageBucket: "digitizeme-com.appspot.com",
             messagingSenderId: "587045959160",
               appId: "1:587045959160:web:8ee5d0f619e2bde432920d",                 measurementId: "G-6CLXMSZ7LJ"
};
let firebaseConfig = firebase.initializeApp(config);
export default firebaseConfig;

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const ref = firebase.database().ref();
export const firebaseAuth = firebase.auth;
