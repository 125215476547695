import React from "react";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import {loginAction, loginActionGoogle, updateGoogleAction} from "actions/loginAction";
import {firebaseAuth} from 'firebaseConfig'; 

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Alert,
  UncontrolledAlert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  Row,
  Col
} from "reactstrap";
// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import { Line, Bar } from "react-chartjs-2";

const Spacer = require('react-spacer')

class Finances extends React.Component {

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
  };


  constructor(props) {
      super(props);
      this.state = {...props, email: "", password: "" }
    }
  

  componentWillMount()    {
      if (this.state.authState.loggedIn === undefined) { 
        console.log("Authstate unconfirmed")
        if (localStorage.getItem("appToken") !== undefined) {
          console.log("appToken found")
          try {
            const firebaseUser = localStorage.getItem("firebaseUser")
            this.setState({authState: {loggedIn:true, user:firebaseUser } });
          } catch(e) { console.error(e); console.log("unable to restore from localStorage")}
        }
      } else { console.log("Authstate confirmed") }
  
      firebaseAuth().onAuthStateChanged(user => {
          if (user) {
             // updateGoogleAction()
              console.log("User signed in: ", JSON.stringify(user.displayName));
              // localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("appToken", user.uid);
              this.setState({authState: {loggedIn:true, user:user} });
              // this.props.history.push("/client/profile")//, this.state)
          }
      });
   }   
  render() {
    // if (!this.props.authState.loggedIn) { 


    //   // this.props.history.push("/client/profile");
    //   return (//<> 
    //      <Redirect to="/home/login" />
    //   //   <Col lg="5" md="7">
    //   //     <Card className="bg-secondary shadow border-0">
    //   //       <CardHeader className="bg-transparent pb-5">
    //   //         <div className="text-muted text-center mt-2 mb-3">
    //   //           Signed In
    //   //        </div>
    //   //       </CardHeader>
    //   //       <CardBody className="px-lg-5 py-lg-5">
    //   //         <div className="text-center text-muted mb-4">
    //   //           <Button
    //   //           href="/client/profile"
    //   //           > Get Started </Button>
    //   //         </div>
    //   //       </CardBody>
    //   //     </Card>
    //   //   </Col>
    //     //</>
    //     );
    //   // this.props.history.push("/client/profile")
    // } else { 
      return (
      <>

        <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>        
          <Row>   
            <Col xs="7">
              <Card className="card-chart" >
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="7">
                      <h5 className="card-category">Artificial intelligence software market revenue worlwide 2018-2025</h5>
                      <CardTitle tag="h2">Revenue in billion U.S. Dollars</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area" style={{height:'435px'}}>
                    <Line
                      data={chartExample1[this.state.bigChartData]}
                      options={chartExample1.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="5">
              <Card className='card-pricing card-primary'>
              { // <CardHeader>
                // </CardHeader>
              }
                <CardBody>
                <CardTitle tag='h3'>AI vs Humans</CardTitle>                
                <CardImg alt='...' src={require("assets/img/charts/AIvsHumanProgress_400.jpg")}/>
                  <div className="card-prices"><br/>
                    <div className="text-on-front">
                      <h4>Let us help you unlock your company's true potential.</h4>
                      <h4>Speak with one of our specialists about the opportunities.</h4> 
                    </div>
                  </div>
                </CardBody>
              </Card>                
            </Col>                       
          </Row>


          <Row>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Personal</CardTitle>
                </CardHeader>
                <CardBody>
                <div style={{ display:'flex'}}>
                  <Spacer grow='1'/>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Certificates</CardTitle>
                </CardHeader>
                <CardBody>
                
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
// }
const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  updateGoogleAction: () => dispatch(updateGoogleAction()),
  loginAction: (email, password) => dispatch(loginAction(email, password)),
  loginActionGoogle: () => dispatch(loginActionGoogle())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Finances);
