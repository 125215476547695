class Portfolio {
  AD_PRICE = 0; //0.05;
  EXPENSES_PER_MONTH = 0; //250000;
  USER_COUNT = 0; //250000;
  DEV_MONTHS = 0; //7;
  CUSTOMERS_MONTHS = 0; //18;
  GROWTH = 0; //5; //retirement - age > 0 ? retirement - age : 0;
  ADS_PER_HOUR = 0; //2;
  HOURS_PER_DAY = 0; //1;
  ONE_TIME_EXPENSE = 0; //150000
}

let profitSeries = [];

function calculate(p) {
  profitSeries = [];
  // let INFLATION_SO_FAR = 1; // For calculation purposes
  let current_month = 0;
  let current_value = -1 * p.ONE_TIME_EXPENSE;
  let user_count = 0;
  let maxDrawdown = 0
     
  for (var i = 0; i < p.CUSTOMERS_MONTHS; i++) {
    let revenue = 0
    if (i > 0) {
        current_value -= p.EXPENSES_PER_MONTH;
        if (i >= p.DEV_MONTHS + 2)  {
          if (user_count === 0) user_count = 15000
          user_count = user_count * (1+(p.GROWTH * 0.01))  
          revenue = user_count * p.AD_PRICE * p.ADS_PER_HOUR * p.HOURS_PER_DAY * 20
          current_value += revenue
        }
        if (current_value < maxDrawdown) { maxDrawdown = current_value; }
      }
    
    // console.log(user_count, revenue, current_value, p.EXPENSES_PER_MONTH)
    profitSeries.push({
      month: i, //current_month + i,
      worth: current_value,
      users: user_count,
      maxDrawdown: maxDrawdown
    });

  }
  // console.log("<Net worth estimates (inflation adjusted).>\n");
  // console.log(p.TAG_LINE);
}

export default function plan({
  adPrice = 0, //5,
  expense = 0, //200,
  userCount = 0, //200,
  devMonths = 0, //7,
  monthsToGainCustomers = 0, //12,
  growth = 0, //12,
  adsPerHour = 0, //2,
  hoursPerDay = 0, //2,
  oneTimeExpense = 0, //150,
}) {
  let p = new Portfolio();
  p.AD_PRICE = adPrice*0.01;
  p.ONE_TIME_EXPENSE = oneTimeExpense * 1000;
  p.EXPENSES_PER_MONTH = expense * 1000;
  p.USER_COUNT = userCount;
  p.DEV_MONTHS = devMonths;
  p.CUSTOMERS_MONTHS = monthsToGainCustomers;
  p.GROWTH = growth; //retirement - age > 0 ? retirement - age : 0;
  p.ADS_PER_HOUR = adsPerHour;
  p.HOURS_PER_DAY = hoursPerDay;
  
  calculate(p);
  return profitSeries;
}
