import React from "react";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import {loginAction, loginActionGoogle, updateGoogleAction} from "actions/loginAction";
import {firebaseAuth, ref} from 'firebaseConfig'; 

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Alert,
  UncontrolledAlert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

const Spacer = require('react-spacer')
const contactFormRef = ref.child("users/"+localStorage.getItem("appToken")+"/contact_form");
class Contact extends React.Component {

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
  };


  constructor(props) {
      super(props);
      this.state = {...props,       
        form: [],
        alert: false,
        alertData: {}
      }; 
    }

  showAlert(type, message) {
    this.setState({
      alert: true,
      alertData: { type, message }
    });
    setTimeout(() => {
      this.setState({ alert: false });
    }, 4000)
  }

  resetForm() {
    this.refs.contactForm.reset();
  }
  

  componentWillMount()    {
      if (this.state.authState.loggedIn === undefined) { 
        console.log("Authstate unconfirmed")
        if (localStorage.getItem("appToken") !== undefined) {
          console.log("appToken found")
          try {
            const firebaseUser = localStorage.getItem("firebaseUser")
            this.setState({authState: {loggedIn:true, user:firebaseUser } });
          } catch(e) { console.error(e); console.log("unable to restore from localStorage")}
        }
      } else { console.log("Authstate confirmed") }
  
      firebaseAuth().onAuthStateChanged(user => {
          if (user) {
             // updateGoogleAction()
              console.log("User signed in: ", JSON.stringify(user.displayName));
              // localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("appToken", user.uid);
              this.setState({authState: {loggedIn:true, user:user} });
              // this.props.history.push("/client/profile")//, this.state)
          }
      });

      let formRef = contactFormRef.orderByKey().limitToLast(6);
        formRef.on('child_added', snapshot => {
        const { name, email, phone, message } = snapshot.val();
        const data = { name, email, phone, message };
        this.setState({ form: [data].concat(this.state.form) });
      })      
   }   
  sendMessage(e) {
    e.preventDefault();
    const params = {
      name: this.inputName.value,
      email: this.inputEmail.value,
      city: this.inputCity.value,
      phone: this.inputPhone.value,
      message: this.textAreaMessage.value
    };
    if (params.name && params.email && params.phone && params.phone && params.message) {
        contactFormRef.push(params).then(() => {
        this.showAlert('success', 'Your message was sent successfull');
      }).catch(() => {
        this.showAlert('danger', 'Your message could not be sent');
      });
      this.resetForm();
    } else {
      this.showAlert('warning', 'Please fill the form');
    };
  }   
  render() {
    // if (!this.props.authState.loggedIn) { 


    //   // this.props.history.push("/client/profile");
    //   return (//<> 
    //      <Redirect to="/home/login" />
    //   //   <Col lg="5" md="7">
    //   //     <Card className="bg-secondary shadow border-0">
    //   //       <CardHeader className="bg-transparent pb-5">
    //   //         <div className="text-muted text-center mt-2 mb-3">
    //   //           Signed In
    //   //        </div>
    //   //       </CardHeader>
    //   //       <CardBody className="px-lg-5 py-lg-5">
    //   //         <div className="text-center text-muted mb-4">
    //   //           <Button
    //   //           href="/client/profile"
    //   //           > Get Started </Button>
    //   //         </div>
    //   //       </CardBody>
    //   //     </Card>
    //   //   </Col>
    //     //</>
    //     );
    //   // this.props.history.push("/client/profile")
    // } else { 
      return (
      <>
        {this.state.alert && <div className={`alert alert-${this.state.alertData.type}`} role='alert'>
          <div className='container'>
            {this.state.alertData.message}
          </div>
        </div>}

        <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <Row>

        <Col md="6">
          <Card className="shadow">
              <CardHeader className="border-0">
                <h2>Contact Form</h2>
              </CardHeader>
              <CardBody>
                  <form onSubmit={this.sendMessage.bind(this)} ref='contactForm' >
                    <div className='form-group'>
                      <label htmlFor='name'>Name</label>
                      <input type='text' className='form-control' id='name' 
                      //placeholder={this.state.authState.user.displayName} 
                      ref={name => this.inputName = name} />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1'>Email</label>
                      <input type='email' className='form-control' id='email' 
                      //placeholder={this.state.authState.user.email} 
                      ref={email => this.inputEmail = email} />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='phone'>Phone</label>
                      <input className='form-control' id='phone' 
                      //placeholder={this.state.authState.user.phone} 
                      ref={phone => this.inputPhone = phone} />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='message'>Message</label>
                      <textarea className='form-control' id='message' rows='3' ref={message => this.textAreaMessage = message}></textarea>
                    </div>
                    <button type='submit' className='btn btn-primary'>Send</button>
                  </form>
            </CardBody>
            </Card>          
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
// }
const mapStateToProps = state => ({
  ...state
});
// const mapDispatchToProps = dispatch => ({
//   updateGoogleAction: () => dispatch(updateGoogleAction()),
//   loginAction: (email, password) => dispatch(loginAction(email, password)),
//   loginActionGoogle: () => dispatch(loginActionGoogle())
// });
export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(Contact);
