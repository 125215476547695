import "rc-slider/assets/index.css";
import AutosizeInput from "react-input-autosize";
import "./chart.css";
// import styled from "styled-components";
import React from "react";
import { connect } from "react-redux";
import Slider, { createSliderWithTooltip } from "rc-slider";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";

const SliderWithTooltip = createSliderWithTooltip(Slider);

Table.propTypes = {
// Pass in a Component to override default element
// tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
// size: PropTypes.string,
bordered: false,
borderless: false,
striped: true,
dark: true,
// hover: PropTypes.bool,
responsive: true,
// Custom ref handler that will be assigned to the "ref" of the inner <table> element
// innerRef: PropTypes.oneOfType([
//   PropTypes.func,
//   PropTypes.string,
//   PropTypes.object
// ])
};


let S = {};

// S.container = styled.div`
//   /* margin-top: 5vw; */
//   margin-bottom: 10vw;
//   margin-left: 10vw;
//   margin-right: 10vw;
// `;


class ChartSettings extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        ...props,
          adPrice: 10,
          expense: 200,
          userCount: 200,
          devMonths: 7,
          monthsToGainCustomers: 18,
          growth: 5,
          adsPerHour: 2,
          hoursPerDay: 1,
    };
  //       // this.onDevMonthsChange = this.onDevMonthsChange.bind(this);
  //       // this.onGrowthChange = this.onGrowthChange.bind(this);
  //       // this.onAdPriceChange = this.onAdPriceChange.bind(this);
  //       // this.onMonthsToGainCustomersChange = this.onMonthsToGainCustomersChange.bind(this);
  //       // this.onAdsPerHourChange = this.onAdsPerHourChange.bind(this);
  //       // this.onHoursPerDayChange = this.onHoursPerDayChange.bind(this);
  //       // this.onExpenseChange = this.onExpenseChange.bind(this);
  //       // this.onUserCountChange = this.onUserCountChange.bind(this);        
    }

  // componentWillReceiveProps(props) {
  //   this.setState({ devMonths: props.devMonths })
  //   this.setState({ hoursPerDay: props.hoursPerDay })

  // }
              // <td className="text-right">
              //   {"Daily Active Users:"}
              // </td>
              // <td className="text-left">
              //   <AutosizeInput
              //     name="form-field-name"
              //     type="number"
              //     value={this.props.userCount}
              //     onChange={this.state.onUserCountChange}
              //   /> { " thousand" }
              // </td>

  SettingsTable = (props) => {
    return (
      <>
        <Table size='sm'>
          <tbody>
            <tr>
                <td className="text-right">         
                  {"Expense per Month:  "}
                </td>
                <td className="text-left">
                    <AutosizeInput
                      name="form-field-name"
                      type="number"
                      value={this.props.expense}
                      onChange={this.state.onExpenseChange}
                    /> { " thousand" } 
                </td>            
                <td className="text-right">
                    {"Ads per Hour:  "}
                </td>
                <td className="text-left">
                    <AutosizeInput
                      name="form-field-name"
                      type="number"
                      value={this.props.adsPerHour}
                      onChange={this.state.onAdsPerHourChange}
                    />             
                </td>
                <td className="text-right">
                  {"Hours per Day:"}
                </td>
                <td className="text-left">
                  <AutosizeInput
                    name="form-field-name"
                    type="number"
                    value={this.props.hoursPerDay}
                    onChange={this.state.onHoursPerDayChange}
                  />
                </td>
            </tr>
            </tbody>
            </Table>
        <Table size='sm'>
          <tbody>

            <tr>
              <td className="text-left">
                {"Current Ad Price:  "}
                {this.props.adPrice * 0.01}
                <SliderWithTooltip
                  defaultValue={this.props.adPrice}
                  onChange={this.state.onAdPriceChange}
                  max={1}
                  max={30}
                />
              </td>
              <td className="text-left">
                <p>Development Months: {this.props.devMonths}</p>
                <SliderWithTooltip
                  defaultValue={this.props.devMonths}
                  onChange={this.state.onDevMonthsChange}
                  max={3}
                  max={10}                  
                />              
              </td>
              <td className="text-left">
                  <p>Months to Gain Customers: {this.props.monthsToGainCustomers}</p>
                    <SliderWithTooltip
                      defaultValue={this.props.monthsToGainCustomers}
                      onChange={this.state.onMonthsToGainCustomersChange}
                      max={3}
                      max={28}

                    />              
              </td>
              <td className="text-left">
                  <p>User Growth per month: {this.props.growth}%</p>
                   <SliderWithTooltip
                     defaultValue={this.props.growth}
                     onChange={this.state.onGrowthChange}
                    max={1}
                    max={20}
                  />               
              </td>
            </tr>
          </tbody>
        </Table>
        </>
    );
}
  render() {
    // <S.container>


        
    //   </S.container>

    return (
  		<> 
        <this.SettingsTable/>
      </>   
    );

  }
}
export default ChartSettings;

// const mapStateToProps = state => ({
//   ...state
// });
// const mapDispatchToProps = dispatch => ({
// });
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ChartSettings);
