import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
//import Rtl from "views/Rtl.js";
import Budget from "views/Budget.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import Home from "views/Home.js";
import Contact from "views/Contact.js";
import Login from "views/Login.js";
import Logout from "views/Logout.js";
import Biography from "views/Biography.js";
import Finances from "views/Finances.js";

var routes = [
    
  {
    name: "Login",
    // rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    layout: "/public",
    path: "/login",
    component: Login,
    invisible: true
  },
  {
    name: "Logout",
    icon: "tim-icons icon-chart-pie-36",
    layout: "/client",
    path: "/logout",
    component: Logout,
    invisible: true
  },
  {
    name: "Biography",
    icon: "tim-icons icon-pin",
    layout: "/public",
    path: "/bio",
    component: Biography,
    invisible: true
  },
  {
    path: "/home",
    name: "Home",
    // rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Home,
    layout: "/public"
  },
  {
    name: "Finances",
    icon: "tim-icons icon-key-25",
    layout: "/client",
    path: "/finances",
    component: Finances,
  },  
  {
    name: "Contact",
    icon: "tim-icons icon-key-36",
    layout: "/public",
    path: "/contact",
    component: Contact,
  },  

  {
    path: "/dashboard",
    name: "Dashboard",
    // rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/public",
    invisible: true

  },
  {
    path: "/icons",
    name: "Icons",
    // rtlName: "الرموز",
    icon: "tim-icons icon-atom",
    component: Icons,
    layout: "/public",
    invisible: true
  },
  {
    path: "/map",
    name: "Map",
    // rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    component: Map,
    layout: "/client",
    invisible: true

  },
  {
    path: "/notifications",
    name: "Notifications",
    // rtlName: "إخطارات",
    icon: "tim-icons icon-bell-55",
    component: Notifications,
    layout: "/client",
    invisible: true

  },
  {
    path: "/profile",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/client",
    authenticated: true

  },
  {
    path: "/tables",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-puzzle-10",
    component: TableList,
    layout: "/client",
    invisible: true

  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: "tim-icons icon-align-center",
    component: Typography,
    layout: "/client",
    invisible: true

  },
  {
    name: "Budget",
    icon: "tim-icons icon-chart-pie-36",
    layout: "/public",
    path: "/budget",
    component: Budget,
    invisible: true
  },

  // {
  //   path: "/rtl-support",
  //   name: "RTL Support",
  //   rtlName: "ار تي ال",
  //   icon: "tim-icons icon-world",
  //   component: Rtl,
  //   layout: "/rtl"
  // }
];
export default routes;
